import logo from '../assets/logo.svg';
import bmbf from '../assets/bmbf.svg';

const Header: React.FC = props => {
    return (
      <header className="header">
        <div className="header-container">
            <div className="logo-container">
                <img className="header-logo" src={logo} alt="Logo von Medtec Online"></img>
            </div>
            <div className='bmbf-container'>
                <span className='bmbf-text'>Eine Initiative vom</span>
                <div className='bmbf-logo-container'>
                    <img className='bmbf-logo' src={bmbf} alt="Logo des Bundesministerium für Bildung und Forschung"></img>
                </div>
            </div>
        </div>
      </header>
    );
};

export default Header;