import { useState } from 'react';
import './App.css';
import ComponentSwitcher from './components/ComponentSwitcher';
import FormList from './components/FormList';
import Login from './components/Login';
import Header from './components/Header';
import FormDetail from './components/FormDetail';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';

function App() {
  const [activePage, setActivePage] = useState('login');
  const [activeFormId, setActiveFormId] = useState('');

  return (
    <div className="App">
      <BrowserRouter>
        {<Header></Header>}
        <Routes>
          <Route path="/fillForm/:id" element={
            <FormDetail name="formDetail" setPage={setActivePage} id={'routeId'}></FormDetail>
          }></Route>
          <Route path="/" element={
            <ComponentSwitcher active={activePage}>
              <Login name="login" setPage={setActivePage}></Login>
              <FormList name="list" setPage={setActivePage} setActiveFormId={setActiveFormId}></FormList>
              <FormDetail name="formDetail" setPage={setActivePage} id={activeFormId}></FormDetail>
          </ComponentSwitcher>
          }></Route>
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
        {activePage === 'login' ? null : <Footer></Footer>}
      </BrowserRouter>
    </div>
  );
}

export default App;
