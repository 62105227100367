import { Box, IconButton } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from "react-router-dom";

interface FormDetailProps {
    id: string,
    setPage: Dispatch<SetStateAction<string >>,
    name: string
}

const FormDetail: React.FC<FormDetailProps> = props => {
    const [url, setUrl] = useState('');
    const { id } = useParams();

    useEffect(() => {
        let formId;
        if (id) {
            formId = id;
            props.setPage('formDetail');
        } else {
            formId = props.id;
        }
        setUrl('https://tools.medteconline.de/fillForm/' + formId);
    },[props, id]);

    const handleClose = () => {
        props.setPage('list');
    }

    const showFormDetail = () => {
        return (
            <>
                <Box sx={{backgroundColor: '#F5F6F7', height:'calc(100% - 86px)', display: 'flex', flexDirection:'column'}}>
                    <div className="detail-container" style={{marginLeft: '32px', marginRight: '32px', marginTop: '32px', marginBottom: '32px', backgroundColor: 'white', borderRadius: '8px', borderWidth: '1px', borderBlockColor: 'rgb(214, 215, 217)', height: '100%'}}>
                        {id ? null : <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                            position: 'absolute',
                            right: 48,
                            top: 126,
                            color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>}
                        <iframe title={url} src={url} width="100%" style={{'border': 'none', 'height': 'calc(100% - 8px)', marginTop: '4px'}}></iframe>
                    </div>
                </Box>
            </>
        );
    };

    return showFormDetail();
};

export default FormDetail;
