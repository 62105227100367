import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { alpha, Button, FormControl, Grid, InputBase, InputLabel, styled } from "@mui/material";

const StyledInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: '12px',
    position: 'relative',
    // backgroundColor: '#FFF',
    background: "rgb(255, 255, 255)",
    border: '1px solid',
    borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
    fontSize: 16,
    lineHeight: 1.5,
    height: '1.1876em',
    width: '100%',
    padding: '18.5px 14px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Helvetica Now Text',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

interface LoginProps {
    name: string,
    setPage: Dispatch<SetStateAction<string >>
}

const Login: React.FC<LoginProps> = props => {
  // Local parameters
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [displayLogin, setDisplayLogin] = useState(false);

  useEffect(() => {
    // last login date check
    const lastLogin = localStorage.getItem('lastLogin');
    if (lastLogin && lastLogin.length) {
      const lastLoginDate = new Date(Number(lastLogin));
      lastLoginDate.setDate(lastLoginDate.getDate() + 1);

      if (lastLoginDate > new Date()) {
        props.setPage('list');
      } else {
        setDisplayLogin(true);
      }
    } else {
      setDisplayLogin(true);
    }
  },[props]);

  // Event handlers
  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  // Perform Login action
  const handleLogin = async () => {
    if (username === 'admin' && password === 'phitech') {
        // Log in
        setErrorMessage('');
        localStorage.setItem('lastLogin', Date.now().toString());
        props.setPage('list');
    } else {
        setErrorMessage('Invalid credentials')
    }
  };
  
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const showLoginPage = () => {
    return (
      <>
        {displayLogin ?
          <div className="login-container" style={{height:'calc(100% - 86px)', display: 'flex', flexDirection:'column'}}>
              <div style={{backgroundColor: '#F5F6F7', flex: 'auto'}}>
                <h1 className="login-title" style={{paddingTop:'16px', marginBlock:'0', paddingLeft: '16px'}}>Welcome back!</h1>
                <div className="login-form" style={{paddingTop:'64px'}}>
                  <Grid container>
                    <Grid item xs={12} lg={6} sx={{padding: '16px', paddingLeft: '16px'}}>
                      <FormControl variant="standard" sx={{width: '100%'}}>
                        <InputLabel shrink htmlFor="bootstrap-input">
                          Username
                        </InputLabel>
                        <StyledInput defaultValue={username} id="username-input" onChange={handleUsernameChange}/>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6} sx={{padding: '16px'}}>
                      <FormControl variant="standard" sx={{width: '100%'}}>
                        <InputLabel shrink htmlFor="bootstrap-input">
                          Password
                        </InputLabel>
                        <StyledInput defaultValue={password} id="password-input" onChange={handlePasswordChange}/>
                      </FormControl>
                    </Grid>
                  </Grid>
                  
                  {/* <TextField id="outlined-applogin" label="Username" variant="outlined" defaultValue={username} onChange={handleUsernameChange} sx={{
                    'marginTop': '8px',
                  }}/>
                  <FormControl variant="outlined" sx={{'marginTop': '8px',}}>
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      defaultValue={password}
                      onChange={handlePasswordChange}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                  {errorMessage && <div className="error-message">{errorMessage}</div>} */}
                  </div>
              </div>
              <div style={{height: '80px', flex: 'none', width: '100%', display: 'flex', flexDirection: 'column'}}>
                <div style={{paddingRight:'16px', paddingBottom: '16px', paddingTop: '16px', alignSelf: 'flex-end'}}>
                  <Button className="default-button" variant="contained" disabled={username.length === 0 || password.length === 0} onClick={handleLogin} size="large" style={{textTransform: 'none'}}>Login</Button>
                </div>
              </div>
            </div>
         : null}
      </>
    );
  };

  return showLoginPage();
};

export default Login;
