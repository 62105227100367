import { Link } from '@mui/material';

const Footer: React.FC = props => {
    return (
      <>
        <div style={{height: '40px', fontSize: '12px', justifyContent: 'center', alignItems: 'center', display: 'flex', flexWrap: 'wrap'}}>
            <div style={{width: 'auto', textAlign: 'center', display: 'block'}}>
                <Link href="https://medteconline.de/impressum" underline="hover" style={{marginBottom: '4px', marginTop: '4px', marginLeft: '24px', marginRight: '24px', color: 'black', fontSize: '12px', fontWeight: '400'}}>
                    {'Impressum'}
                </Link>
            </div>
            <div style={{width: 'auto', textAlign: 'center', display: 'block'}}>
                <Link href="https://medteconline.de/datenschutz" underline="hover" style={{marginBottom: '4px', marginTop: '4px', marginLeft: '24px', marginRight: '24px', color: 'black', fontSize: '12px', fontWeight: '400'}}>
                    {'Datenschutz'}
                </Link>
            </div>
        </div>
      </>
    );
};

export default Footer;