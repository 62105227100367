import React, { Dispatch, SetStateAction, useState } from "react";
import { DataGrid, GridColDef, GridEventListener } from "@mui/x-data-grid";
import formData from './../data.json';
import { Box, Button, DialogActions, DialogContentText, TextField } from "@mui/material";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

interface FormListProps {
    name: string,
    setPage: Dispatch<SetStateAction<string >>,
    setActiveFormId: Dispatch<SetStateAction<string >>
}

const FormList: React.FC<FormListProps> = props => {
    // Form filling
    const [openForm, setOpenForm] = useState(false);
    const [currentId, setCurrentId] = useState('');
    
    const data = formData.forms;
    
    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Name', flex: 5 },
        { field: 'id', headerName: 'ID', flex: 4 }
    ];
    
    const handleRowClick: GridEventListener<'rowClick'> = (params) => {
        setCurrentId(params.row.id);
        props.setActiveFormId(params.row.id);
        props.setPage('formDetail');
        // setOpenForm(true);
    };

    // Event handlers
    const handleTemplateIDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentId(event.target.value);
    };

    const handleOpenFormModal = () => {
        setCurrentId('');
        setOpenForm(true);
    }
    
    const handleCloseFormModal = () => {
        setOpenForm(false);
    };
    
    const handleOpenFormById = () => {
        if (currentId.length < 1 || !currentId) {
            return;
        }
        props.setActiveFormId(currentId);
        setOpenForm(false);
        props.setPage('formDetail');
    };

    const showFormList = () => {
        return (
        <>
            <Box sx={{backgroundColor: '#F5F6F7', minHeight:'calc(100% - 86px)', display: 'flex', flexDirection:'column'}}>
                <div style={{flex: 'none'}}>
                    <h1 className="login-title" style={{paddingTop:'16px', marginBlock:'0', paddingLeft: '16px', font: '"Helvetica Now Display", Helvetica, Arial, sans-serif', fontSize: '32px', fontWeight: 500}}>Survey demo</h1>
                </div>
                <div style={{flex: 'auto'}}>
                    <div>
                        <p style={{paddingLeft: '16px'}}>To open a new form, choose a template from the table below</p>
                        <Box sx={{marginLeft: '32px', marginRight: '32px', backgroundColor: 'white', borderRadius: '8px', borderWidth: '1px', borderBlockColor: 'rgb(214, 215, 217)'}}>
                            <DataGrid
                                rows={data}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 20 },
                                    },
                                }}
                                pageSizeOptions={[5, 20, 40]}
                                sx={{paddingLeft: '32px', paddingRight: '32px', paddingTop: '16px'}}
                                onRowClick={handleRowClick}
                            />
                        </Box>
                    </div>
                </div>
                <div style={{height: '120px', width: '100%', flex: 'none'}}>
                    <p style={{paddingLeft: '16px'}}>Alternatively, you can open a new form directly by clicking the following button and providing the template ID</p>
                    <Box sx={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                        <div style={{display: 'flex', alignSelf: 'flex-start', paddingLeft: '16px'}}>
                            <Button className="default-button" variant="contained" onClick={handleOpenFormModal} size="medium" sx={{
                                marginTop: '8px', marginRight: '8px', marginBottom: '8px', textTransform: 'none'}}>Open form by ID</Button>
                        </div>
                    </Box>
                </div>
                <div style={{height: '16px'}}></div>
            </Box>

            {/* Modal window */}
            <Dialog
            open={openForm}
            onClose={handleCloseFormModal}
            PaperProps={{
            component: 'form',
            onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                event.preventDefault();

                handleCloseFormModal();
            },
            }}
            >
                <DialogTitle>Open form by Template ID</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    For direct creation of new form, please provide valid form template ID.
                </DialogContentText>
                    <TextField
                        autoFocus
                        id="templateId"
                        margin="dense"
                        label="Template ID"
                        fullWidth
                        variant="standard"
                        defaultValue={currentId}
                        onChange={handleTemplateIDChange}
                        sx={{'marginTop': '8px'}}
                    />
                </DialogContent>
                <DialogActions>
                    <Button className="default-button-white" variant="contained" onClick={handleCloseFormModal} size="medium" sx={{textTransform: 'none'}}>Cancel</Button>
                    <Button className="default-button" variant="contained" onClick={handleOpenFormById} size="medium" sx={{textTransform: 'none'}}>Open</Button>
                </DialogActions>
            </Dialog>
        </>
        );
    };

    return showFormList();
};

export default FormList;
